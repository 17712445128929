<router-outlet></router-outlet>

@if (!loader.loaded) {
  <div class="preloader">
    <img class="img" src="/assets/svg/logo.svg" alt="">
  </div>
}



