import { Component } from '@angular/core';
import { DataPreloaderService } from './core/services/data-preloader.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {
  title = 'Wild Fortune';

  constructor(
    public loader: DataPreloaderService,
  ) {}

}
