import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter, Routes, withInMemoryScrolling } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { InMemoryCache } from '@apollo/client/cache';
// import { ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
// import { HttpLink } from 'apollo-angular/http';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { GlobalErrorHandler } from './core/helpers/global-error-handler';
// import { provideAppInitializer } from './core/initializer/initializer-queue';
import { CacheControlInterceptor } from './core/interceptors/cache-control.interceptor';
import { HostRequestsInterceptor } from './core/interceptors/host-requests.interceptor';
// import { typePolicies } from './core/services/graphql/cache.graphql';
import { SsWebSocketsModule } from './core/vendor/ss-web-sockets/ss-web-sockets.module';
import {provideAppInitializer} from './core/initializer/initializer.queue';
import {provideABTest} from './core/ab-test/ab-test';
import {abTestList} from './core/ab-test/ab-test.data';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      { path: '', loadChildren: () => import('./page/page.module').then(m => m.PageModule) }
    ]
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
      withFetch(),
    ),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top'
      }),
    ),
    provideAppInitializer(),
    provideNgxMask(),
    provideABTest(abTestList),
    importProvidersFrom(
      BrowserModule.withServerTransition({ appId: 'wd' }),
      FormsModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      SsWebSocketsModule,
      NgxMaskDirective,
      // ApolloModule
    ),
    DecimalPipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheControlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HostRequestsInterceptor,
      multi: true,
    },
    // {
    //   provide: APOLLO_NAMED_OPTIONS,
    //   useFactory(httpLink: HttpLink): NamedOptions {
    //     return {
    //       PlatformApi: {
    //         cache: new InMemoryCache({
    //           typePolicies: {
    //             ...typePolicies,
    //           },
    //         }),
    //         link: httpLink.create({
    //           uri: environment.platform_graphql,
    //           withCredentials: true,
    //         }),
    //         ssrMode: true,
    //         ssrForceFetchDelay: 100,
    //         connectToDevTools: true,
    //       },
    //     };
    //   },
    //   deps: [HttpLink],
    // }
  ]
};
